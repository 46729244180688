
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs,
  unref
} from 'vue';
import { useRoute, LocationQuery, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ElForm, ElMessage, ElMessageBox, ElInput } from 'element-plus';

// import LangSelect from '@/components/lang_select/Index.vue';
// import SocialSign from './components/SocialSignin.vue';
import { Rule } from 'async-validator';
import { useStore } from '@/store';
import { UserActionTypes } from '@/store/modules/user/action-types';
import {
  ResponseError,
  login
} from '@/services/api';

const REQUIRED_RULE: Rule = {
  required: true,
  message: '此為必填欄位'
};

const LOGIN_RULES = {
  username: [REQUIRED_RULE],
  password: [REQUIRED_RULE]
};

export default defineComponent({
  components: {
    // LangSelect
    // SocialSign
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const userNameInputRef = ref<typeof ElInput | null>(null);
    const passwordInputRef = ref < typeof ElInput | null>(null);
    const loginFormRef = ref<typeof ElForm | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const logoSource = require('@/assets/images/login/logo-white.png');

    const loginFormData = reactive({
      username: '',
      password: ''
    });

    const isLoading = ref(false);

    const state = reactive({
      passwordType: 'password',
      showDialog: false,
      capsTooltip: false,
      redirect: '',
      otherQuery: {}
    });

    const checkCapsLock = (e: KeyboardEvent) => {
      const { key } = e;
      if (key) {
        state.capsTooltip = key !== null && key.length === 1 && key >= 'A' && key <= 'Z';
      }
    };

    const showPwd = () => {
      if (state.passwordType === 'password') {
        state.passwordType = '';
      } else {
        state.passwordType = 'password';
      }

      nextTick(() => {
        passwordInputRef.value && passwordInputRef.value.focus();
      });
    };

    const showQrCode = async(qrCode: string) => {
      const { value: code } = await ElMessageBox.prompt(
        qrCode
          ? `
          <div class="qr-code-container">
            <img class="qr-code-img" src="https://chart.googleapis.com/chart?cht=qr&chl=${qrCode}&chs=100" alt="QR code" />
          </div>
          `
          : '',
        '2FA Code',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '確認',
          cancelButtonText: '取消'
        }
      );

      return code;
    };

    const adminLogin = (event: KeyboardEvent) => {
      const loginForm = unref(loginFormRef);

      (event.target as HTMLInputElement).blur();
      loginForm && loginForm.validate(async(valid: boolean) => {
        if (valid) {
          isLoading.value = true;

          try {
            // get admin username and password, then get qrCode and secret string
            const { data } = await login({ data: loginFormData });
            const { qrCode, secret, username } = data;

            // show QRCode and get code
            const code = await showQrCode(qrCode);

            // use code to do TOTP login
            await store.dispatch(UserActionTypes.ACTION_LOGIN, {
              secret,
              code,
              username
            });

            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              });
          } catch (error) {
            console.log('error', (error as ResponseError).response?.data);
            ElMessage.error((error as ResponseError).response?.data.error.message);

            isLoading.value = false;
            loginForm.resetFields();
          }
        }
      });
    };

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {} as LocationQuery);
    }

    watch(() => route.query, query => {
      if (query) {
        state.redirect = query.redirect?.toString() ?? '';
        state.otherQuery = getOtherQuery(query);
      }
    });

    onMounted(() => {
      if (loginFormData.username === '') {
        userNameInputRef.value && userNameInputRef.value.focus();
      } else if (loginFormData.password === '') {
        passwordInputRef.value && passwordInputRef.value.focus();
      }
    });

    return {
      userNameInputRef,
      passwordInputRef,
      loginFormRef,
      loginFormData,
      LOGIN_RULES,
      isLoading,
      logoSource,
      checkCapsLock,
      showPwd,
      adminLogin,
      ...toRefs(state),
      t
    };
  }
});
